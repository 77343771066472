import axios, { AxiosResponse, Method, AxiosPromise } from 'axios';
import { stringify } from 'qs';

import { TEditorBlockData } from 'components/edit/HTMLEditor/types';
import { ITableChangeState } from 'store/common/List';
import notifyStore from 'store/notification';
import authStore from 'store/auth';
import prepareError from 'lib/prepareError';
import { AlertOptions } from 'react-notification-alert';
import { TTableFilters } from 'lib/types';
import { FcmType } from 'types';

//пока не надо возвращать куки на сервер
//export const axiosInstance = axios.create({ withCredentials: true });
export const axiosInstance = axios.create();

//*************************************************************************************************************
//Во всех исходящих запросах в заголовок записывается access токен / refresh токен
axiosInstance.interceptors.request.use(
    (config) => {
        const v = config;
        let token = '';

        if (
            authStore.factor2Token &&
            (v.url?.includes('auth/login-by-factor2-code') || v.url?.includes('auth/repeat-factor2-code'))
        ) {
            token = authStore.factor2Token;
        } else if (v.url?.includes('auth/refresh')) {
            token = authStore.refreshToken || '';
        } else {
            token = authStore.accessToken || '';
        }

        if (token) {
            if (!v.headers) v.headers = {};
            v.headers.Authorization = `Bearer ${token}`;
        }
        return v;
    },
    (error) => {
        return Promise.reject(error);
    }
);

//*************************************************************************************************************
//Перехватить ошибку 401 (не авторизован) в ответе на запрос
//и обновить пару токенов.

// переменная для хранения запроса refresh токена
let refreshTokenRequest: AxiosPromise | null = null;

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const { config } = error.response;
        //если код ответа 401 (не авторизован) и это не повторный запрос (например, с новым access токеном) и не запрос на login
        //то запросить новую пару токенов и затем повторить исходный запрос, но с новым access токеном
        if (error.response?.status === 401 && !config.__retry__ && !config.url?.includes('auth/login')) {
            //__retry__ - признак повторного запроса. Размещается в config и потом axios его будет возвращать
            config.__retry__ = true;

            //обновить пару токенов
            let accessToken;
            let refreshToken;
            try {
                //запрос выполняется только, если его еще не было сделано
                if (refreshTokenRequest === null) {
                    const configRefresh = {
                        method: 'get' as Method,
                        url: '/auth/refresh',
                        __retry__: true,
                    };
                    //здесь без await - только промис запоминаем
                    refreshTokenRequest = axiosInstance(configRefresh);
                }
                //здесь ждем разрешения промиса, т.е. все параллельные исходные запросы будут ждать результат
                const result = await refreshTokenRequest;
                accessToken = result.data.accessToken;
                refreshToken = result.data.refreshToken;
            } catch (e) {
                accessToken = null;
                refreshToken = null;
            } finally {
                refreshTokenRequest = null;
            }

            //при ошибке - выход
            if (!accessToken || !refreshToken) authStore.logout();

            //повторяем исходный запрос
            authStore.setToken(accessToken, refreshToken);
            return axiosInstance(config);
        }

        notifyStore.showNotify({
            place: 'br',
            message: prepareError(error),
            type: 'danger',
        } as AlertOptions);

        return Promise.reject(error);
    }
);

type TCreatedFile = {
    success: 1 | 0;
    file?: { url: string; id: number };
};

const paramsSerializer = (pars: string) => stringify(pars, { arrayFormat: 'brackets' });

export type TSelectResponse = Promise<
    AxiosResponse<{
        result: Array<{ value: number; label: string; selected?: boolean }>;
    }>
>;

export interface IGetSelectParams {
    defaultSelects?: number[];
    searchText?: string;
}

const generateRequestsByItemName = (itemName: string) => ({
    //persistedFilters - постоянные условия фильтров - сохраняются между обновлениями компонентов:
    //будут добавляться к методу getList в подключенном api через pullList
    //если пусто, то не добавляются
    getList: (params: ITableChangeState, persistedFilters: TTableFilters) => {
        const { filters, sizePerPage, page, sortField, sortOrder } = params;

        //скопировать объект фильтров и добавить новые свойства в копию
        const newFilters = {};
        Object.assign(newFilters, filters, persistedFilters);

        return axiosInstance.get(itemName, {
            params: { page, sizePerPage, sortField, sortOrder, filters: newFilters },
            paramsSerializer,
        });
    },
    deleteOne: (id: number) => axiosInstance.delete(`${itemName}/${id}`),
    getOne: (id: number) => axiosInstance.get(`${itemName}/${id}`),
    updateOne: (id: number, data: object) => axiosInstance.patch(`${itemName}/${id}`, data),
    createOne: (data: object) => axiosInstance.post(`${itemName}`, data),
    getSelect: (params: IGetSelectParams): TSelectResponse =>
        axiosInstance.get(`${itemName}/select`, { params, paramsSerializer }),
});

export const reportApi = {
    ...generateRequestsByItemName('reporting/rpt'),
    //сформировать отчет
    job: (data: object) => axiosInstance.post(`reporting/job`, data),
};

export const quizResultApi = {
    ...generateRequestsByItemName('quizes/results'),
};

export const articleApi = {
    ...generateRequestsByItemName('articles'),
};

export const userApi = {
    ...generateRequestsByItemName('users'),
};

export const mobilerApi = {
    ...generateRequestsByItemName('mobilers'),
};

export const tagApi = {
    ...generateRequestsByItemName('tags'),
};

export const channelApi = {
    ...generateRequestsByItemName('channels'),
};

export const journalApi = {
    ...generateRequestsByItemName('journals'),
    //парсинг сайта с хранилищем журналов
    parser: () => axiosInstance.get(`journals/parser`),
};

export const tgChannelApi = {
    ...generateRequestsByItemName('tg-channels'),
};

export const stationApi = {
    ...generateRequestsByItemName('stations'),
};

export const supportApi = {
    ...generateRequestsByItemName('support'),
};

export const commentApi = {
    ...generateRequestsByItemName('comments'),
};

export const adviceApi = {
    ...generateRequestsByItemName('advices'),
};

export const quizApi = {
    ...generateRequestsByItemName('quizes'),
};

export const roleApi = {
    ...generateRequestsByItemName('role'),
};

export const authApi = {
    //прочитать user по сохраненному access токену
    loginByToken: () => axiosInstance.get('auth/login-by-token'),
    //войти в систему по логин/пароль
    login: ({ username, password }: { username: string; password: string }) =>
        axiosInstance.post('auth/login', { username, password }),
    //Логин по factor2 коду
    loginByFactor2Code: (code: string) => axiosInstance.post('auth/login-by-factor2-code', { code }),
    //Повторный запрос factor2 кода
    repeatFactor2Code: () => axiosInstance.post('auth/repeat-factor2-code'),
    //выход из системы
    logout: () => axiosInstance.post('auth/logout'),
    //Запросить смену пароля
    startResetPassword: (data: object) => axiosInstance.post(`auth/start-reset-password`, data),
    //Сменить пароль
    resetPassword: (data: object) => axiosInstance.post(`auth/reset-password`, data),
    //Проверить токен смены пароля
    checkResetToken: (data: object) => axiosInstance.post(`auth/check-reset-token`, data),
    //прочитать общедоступные регистрационные данные
    getPublicConfig: () => axiosInstance.get('auth/public-config'),
};

//сервисные функции
export interface IReminders {
    [key: string]: number;
}

export const serviceApi = {
    //прочитать текущий список напоминаний
    getReminders: () => axiosInstance.get(`/system/reminders`),
    //Прочитать список сервисных операций
    getServiceOperations: () => axiosInstance.get(`/system/serviceOperations`),
    //Выполнить сервисную операцию
    runServiceOperation: (name: string) => axiosInstance.get(`/system/run/${name}`),
    //Читать PID серверного процесса
    getPID: (idRequest: number) => axiosInstance.get(`/system/pid/${idRequest}`),
};

export const noticeApi = {
    //прочитать текущий список настроек
    get: () => axiosInstance.get(`/notice`),
    //исправить текущий список настроек
    update: (data: object) => axiosInstance.patch(`/notice`, data),
};

export const telegramApi = {
    //Прочитать список телеграм каналов
    getChannelList: () => axiosInstance.get(`/system/telegram/channels`),
    //опубликовать пост
    createOne: (articleId: number, tgChannelId: number) =>
        axiosInstance.post(`/system/telegram/create`, { articleId, tgChannelId }),
    //исправить пост
    updateOne: (articleId: number, tgChannelId: number) =>
        axiosInstance.post(`/system/telegram/update`, { articleId, tgChannelId }),
    //удалить пост
    deleteOne: (articleId: number, tgChannelId: number) =>
        axiosInstance.post(`/system/telegram/delete`, { articleId, tgChannelId }),
    //Прочитать список каналов с публикацией статьи
    getListPublications: (articleId: number) => axiosInstance.get(`/system/telegram/publications/${articleId}`),
    //проверка формата поста
    check: (tgMedia: TEditorBlockData[], tgText: TEditorBlockData[], signal: AbortSignal) =>
        axiosInstance.post('/system/telegram/check', { tgMedia, tgText }, { signal }),
};

export const loggerApi = {
    ...generateRequestsByItemName('logger'),
};

// *************************************************************************************************************
// Push уведомления
export type TSourceSearchResponse = Promise<
    AxiosResponse<{
        codeResult: 0 | 1 | 2;
        id?: number;
        title?: string;
        cover?: string;
        coverId?: number;
        total?: number;
    }>
>;

export type TSourceSearch = {
    searchType: FcmType;
    searchId: number;
    searchName: string;
};

export const fcmApi = {
    ...generateRequestsByItemName('fcm'),
    //поиск источника уведомления
    sourceSearch: (params: TSourceSearch, signal: AbortSignal): TSourceSearchResponse =>
        axiosInstance.get('fcm/source-search', { params, paramsSerializer, signal }),
};

// *************************************************************************************************************
// Работа с файлами
//
//создать файл
//endpoint - контролер обработки запроса
const createFile = (file: File, endpoint: string): Promise<AxiosResponse<TCreatedFile>> => {
    const formData = new FormData();
    formData.append('files', file);

    return axiosInstance.post<TCreatedFile>(`files/${endpoint}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const fileApi = {
    ...generateRequestsByItemName('files'),
    //  image - картинки
    createImageFile: (file: File): Promise<AxiosResponse<TCreatedFile>> => createFile(file, 'image'),
    //  journal - журналы pdf
    createJournalFile: (file: File): Promise<AxiosResponse<TCreatedFile>> => createFile(file, 'journal'),
};

export const profileApi = {
    //Прочитать
    getOne: () => axiosInstance.get(`/profile`),
    //исправить
    updateOne: (data: object) => axiosInstance.patch(`/profile`, data),
};

export const securityApi = {
    //Прочитать
    get: () => axiosInstance.get(`/security`),
    //исправить
    update: (data: object) => axiosInstance.patch(`/security`, data),
};
