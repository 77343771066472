import React, { useState, useRef, useEffect } from 'react';
import { Form, Row, Col } from 'reactstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import notifyStore from 'store/notification';
import { AlertOptions } from 'react-notification-alert';
import * as yup from 'yup';
import { useFormik } from 'formik';

import * as appConst from 'app.const';
import { usePublicConfig } from 'lib/config';
import { stat } from 'lib/stat';
import FormTextInput from 'components/inputs/FormTextInput';
import { authApi } from 'api';
import LoaderClassic from 'components/LoaderClassic';
import LoginBackground from './LoginBackground';
import LoginButton from './LoginButton';

//статус исполнения запроса
const REQUEST_STATUS_EMPTY = 0; //запроса не было
const REQUEST_STATUS_DONE = 1; //запрос выполнен
const REQUEST_STATUS_ERROR = 2; //ошибка

const ResetPassword: React.FC = () => {
    const { ready: configReady, publicConfig } = usePublicConfig();
    const navigate = useNavigate();
    //строка query из адресной строки
    const [searchParams] = useSearchParams();
    //данные запроса проверки токена
    const [tokenData, setTokenData] = useState({ status: REQUEST_STATUS_EMPTY, token: '', message: '' });
    //данные запроса на update пароля
    const [content, setContent] = useState({ status: REQUEST_STATUS_EMPTY, message: '' });
    //состояние выполнения
    const [running, setRunning] = useState(false);
    //запрет изменения состояния размонированного объекта
    const isMounted = useRef(false);
    //используется для проверки набора полей в целом при submit
    const isFormSubmitting = useRef(false);
    //данные формы ввода
    const initialValues = {
        password: '',
        confirmationPassword: '',
    };

    //*************************************************************************************************************
    //прочитать и проверить токен из адреса
    useEffect(() => {
        isMounted.current = true;
        const token = searchParams.get('token') || '';
        setRunning(true);
        authApi
            .checkResetToken({ token })
            .then((data) => {
                if (isMounted.current) {
                    //проверка токена возвращает true/false
                    if (data.data) setTokenData({ status: REQUEST_STATUS_DONE, token, message: '' });
                    else {
                        const message = 'Некорректный токен';
                        notifyStore.showNotify({
                            place: 'br',
                            message,
                            type: 'success',
                        } as AlertOptions);
                        //а здесь просто сохраним инфо
                        setTokenData({
                            status: REQUEST_STATUS_ERROR,
                            token: '',
                            message,
                        });
                    }
                }
            })
            // eslint-disable-next-line
            .catch((error: any) => {
                if (isMounted.current) {
                    //уведомление об ошибке сервера будет автоматически отображено через ближайший компонент <Notification /> (notifyStore.showNotify)
                    //а здесь просто сохраним инфо
                    const strError = error?.response?.data?.message || '';
                    setTokenData({
                        status: REQUEST_STATUS_ERROR,
                        token: '',
                        message: error.message + (strError ? ', ' : '') + strError,
                    });
                }
            })
            .finally(() => {
                if (isMounted.current) setRunning(false);
            });

        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //*************************************************************************************************************
    //отправить запрос на смену пароля
    const requestResetPasswod = (password: string) => {
        setRunning(true);
        authApi
            .resetPassword({ token: tokenData.token, password })
            .then((data) => {
                if (isMounted.current) setContent({ status: REQUEST_STATUS_DONE, message: '' });
                //событие
                stat.addEvent({ name: 'password_restore', value: { user_id: data.data.id } });
                //перейти на login
                navigate('/auth/login', { replace: true });
            })
            // eslint-disable-next-line
            .catch((error: any) => {
                if (isMounted.current) {
                    //уведомление об ошибке сервера будет автоматически отображено через ближайший компонент <Notification /> (notifyStore.showNotify)
                    //а здесь просто сохраним инфо
                    const strError = error?.response?.data?.message || '';
                    setContent({
                        status: REQUEST_STATUS_ERROR,
                        message: error.message + (strError ? ', ' : '') + strError,
                    });
                }
            })
            .finally(() => {
                if (isMounted.current) setRunning(false);
            });
    };

    //*************************************************************************************************************
    //Используется для проверки свойств для всей формы в целом
    const validate = (values: typeof initialValues) => {
        //если работаем в режиме submit, то проверить набор полей в целом
        if (isFormSubmitting.current) {
            if (values.password !== values.confirmationPassword) {
                const message = 'Пароли должны совпадать';

                return {
                    password: message,
                    confirmationPassword: message,
                };
            }
        }

        return {};
    };

    //*************************************************************************************************************
    //Используется для проверки отдельных свойств формы
    const validationSchema = yup.object().shape({
        password: yup
            .string()
            .min(publicConfig.passwordMinLength, `Должно быть минимум ${publicConfig.passwordMinLength} символов`)
            .max(publicConfig.passwordMaxLength, `Должно быть максимум ${publicConfig.passwordMaxLength} символов`)
            .matches(publicConfig.passwordPattern as RegExp, 'Пароль слишком слабый'),
        confirmationPassword: yup
            .string()
            .min(publicConfig.passwordMinLength, `Должно быть минимум ${publicConfig.passwordMinLength} символов`)
            .max(publicConfig.passwordMaxLength, `Должно быть максимум ${publicConfig.passwordMaxLength} символов`),
    });

    const { dirty, values, handleChange, handleSubmit, errors } = useFormik({
        initialValues,
        validationSchema,
        validate,
        validateOnMount: false,
        //validateOnBlur: false,
        //validateOnChange: false,
        // eslint-disable-next-line
        onSubmit: (data: any) => {
            if (dirty) requestResetPasswod(data.password);
        },
    });

    const handleFormSubmit = async () => {
        //выставить признак проверки набора полей в целом при submit
        isFormSubmitting.current = true;
        handleSubmit();
        //снять признак
        isFormSubmitting.current = false;
    };

    return (
        <LoginBackground>
            <div className="h2 text-left mb-1">Создать новый пароль</div>

            {/* запрос еще не отправлен или ошибка */}
            {content.status === REQUEST_STATUS_EMPTY || content.status === REQUEST_STATUS_ERROR ? (
                <>
                    <div className="text-muted pr-4 mb-3">
                        <small className="text-dark">
                            Длина пароля должна быть не менее 8 символов. Пароль должен состоять из букв латинского
                            алфавита (A-z), арабских цифр (0-9) и специальных символов.
                        </small>
                    </div>
                    <Form onSubmit={handleFormSubmit} className="needs-validation" noValidate={true}>
                        <Row>
                            <Col className="mb-2">
                                <FormTextInput
                                    placeholder="Введите новый пароль"
                                    name="password"
                                    type="password"
                                    value={values.password}
                                    invalidText={errors.password}
                                    handleChange={handleChange}
                                    disabled={!configReady}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3">
                                <FormTextInput
                                    placeholder="Повторите пароль"
                                    name="confirmationPassword"
                                    type="password"
                                    value={values.confirmationPassword}
                                    invalidText={errors.confirmationPassword}
                                    handleChange={handleChange}
                                    disabled={!configReady}
                                />
                            </Col>
                        </Row>
                        {/* кнопка */}

                        <LoginButton
                            isDisabled={running}
                            actionName="СОХРАНИТЬ"
                            type="submit"
                            onClick={handleFormSubmit}
                        />
                    </Form>
                </>
            ) : null}

            {/* индикатор выполнения */}
            {running && (
                <Row className="mt-5 d-flex justify-content-center">
                    <LoaderClassic />
                </Row>
            )}
        </LoginBackground>
    );
};

export default ResetPassword;
