import ArticleList from 'views/articles/ArticleList';
import ArticleEdit from 'views/articles/ArticleEdit';
import ArticleCreate from 'views/articles/ArticleCreate';
import UserList from 'views/users/UserList';
import UserEdit from 'views/users/UserEdit';
import UserCreate from 'views/users/UserCreate';
import MobilerList from 'views/mobilers/MobilerList';
import MobilerEdit from 'views/mobilers/MobilerEdit';
import TagList from 'views/tags/TagList';
import TagEdit from 'views/tags/TagEdit';
import TagCreate from 'views/tags/TagCreate';
import ChannelList from 'views/channels/ChannelList';
import ChannelEdit from 'views/channels/ChannelEdit';
import ChannelCreate from 'views/channels/ChannelCreate';
import TgChannelList from 'views/tgChannels/TgChannelList';
import TgChannelEdit from 'views/tgChannels/TgChannelEdit';
import TgChannelCreate from 'views/tgChannels/TgChannelCreate';
import StationList from 'views/stations/StationList';
import StationEdit from 'views/stations/StationEdit';
import SupportList from 'views/support/SupportList';
import SupportEdit from 'views/support/SupportEdit';
import CommentList from 'views/comments/CommentList';
import CommentEdit from 'views/comments/CommentEdit';
import AdviceList from 'views/advices/AdviceList';
import AdviceEdit from 'views/advices/AdviceEdit';
import QuizList from 'views/quizes/QuizList';
import QuizResultList from 'views/quizes/QuizResultList';
import QuizEdit from 'views/quizes/QuizEdit';
import QuizCreate from 'views/quizes/QuizCreate';
import RoleList from 'views/role/RoleList';
import RoleCreate from 'views/role/RoleCreate';
import RoleEdit from 'views/role/RoleEdit';
import ReportList from 'views/reports/ReportList';
import ReportView from 'views/reports/ReportView';
//import ServiceList from 'views/services';
import JournalList from 'views/journals/JournalList';
import JournalEdit from 'views/journals/JournalEdit';
import JournalCreate from 'views/journals/JournalCreate';
import FcmList from 'views/fcms/FcmList';
import FcmEdit from 'views/fcms/FcmEdit';
import FcmCreate from 'views/fcms/FcmCreate';
//import LoggerList from 'views/logger/LoggerList';
//import LoggerEdit from 'views/logger/LoggerEdit';
import Settings from 'views/settings';
import { TRouteArray } from 'routes/types';

export * from 'routes/types';
export * from 'routes/getRoutes';

const routes: TRouteArray = [
    {
        path: '/articles',
        name: 'Статьи',
        icon: 'ni ni-align-left-2',
        component: ArticleList,
        layout: '/admin',
    },
    {
        isHiddenInMenu: true,
        path: '/articles/:id/edit',
        component: ArticleEdit,
        layout: '/admin',
    },
    {
        isHiddenInMenu: true,
        path: '/articles/create',
        component: ArticleCreate,
        layout: '/admin',
    },
    {
        path: '/tags',
        name: 'Тэги',
        icon: 'ni ni-tag',
        component: TagList,
        layout: '/admin',
    },
    {
        isHiddenInMenu: true,
        path: '/tags/:id/edit',
        component: TagEdit,
        layout: '/admin',
    },
    {
        isHiddenInMenu: true,
        path: '/tags/create',
        component: TagCreate,
        layout: '/admin',
    },
    {
        path: '/channels',
        name: 'Каналы',
        icon: 'fas fa-podcast',
        component: ChannelList,
        layout: '/admin',
    },
    {
        isHiddenInMenu: true,
        path: '/channels/:id/edit',
        component: ChannelEdit,
        layout: '/admin',
    },
    {
        isHiddenInMenu: true,
        path: '/channels/create',
        component: ChannelCreate,
        layout: '/admin',
    },
    {
        path: '/users',
        name: 'Авторы',
        icon: 'fas fa-user-plus',
        component: UserList,
        layout: '/admin',
    },
    {
        isHiddenInMenu: true,
        path: '/users/:id/edit',
        component: UserEdit,
        layout: '/admin',
    },
    {
        isHiddenInMenu: true,
        path: '/users/create',
        component: UserCreate,
        layout: '/admin',
    },
    {
        path: '/mobilers',
        name: 'Пользователи',
        icon: 'fas fa-user',
        component: MobilerList,
        layout: '/admin',
    },
    {
        isHiddenInMenu: true,
        path: '/mobilers/:id/edit',
        component: MobilerEdit,
        layout: '/admin',
    },
    {
        path: '/roles',
        name: 'Роли',
        icon: 'ni ni-briefcase-24',
        component: RoleList,
        layout: '/admin',
    },
    {
        isHiddenInMenu: true,
        path: '/roles/:id/edit',
        component: RoleEdit,
        layout: '/admin',
    },
    {
        isHiddenInMenu: true,
        path: '/roles/create',
        component: RoleCreate,
        layout: '/admin',
    },
    {
        path: '/support',
        name: 'Тех. поддержка',
        icon: 'ni ni-chat-round',
        component: SupportList,
        layout: '/admin',
    },
    {
        isHiddenInMenu: true,
        path: '/support/:id/edit',
        component: SupportEdit,
        layout: '/admin',
    },
    // {
    //     path: '/comments',
    //     name: 'Обр. связь',
    //     icon: 'ni ni-email-83',
    //     component: CommentList,
    //     layout: '/admin',
    // },
    // {
    //     isHiddenInMenu: true,
    //     path: '/comments/:id/edit',
    //     component: CommentEdit,
    //     layout: '/admin',
    // },
    // {
    //     path: '/advices',
    //     name: 'Предложения',
    //     icon: 'fas fa-pen-fancy',
    //     component: AdviceList,
    //     layout: '/admin',
    // },
    // {
    //     isHiddenInMenu: true,
    //     path: '/advices/:id/edit',
    //     component: AdviceEdit,
    //     layout: '/admin',
    // },
    // {
    //     path: '/quizes',
    //     name: 'Викторины',
    //     icon: 'fas fa-book-open',
    //     component: QuizList,
    //     layout: '/admin',
    // },
    // {
    //     isHiddenInMenu: true,
    //     path: '/quizes/:id/edit',
    //     component: QuizEdit,
    //     layout: '/admin',
    // },
    // {
    //     isHiddenInMenu: true,
    //     path: '/quizes/create',
    //     component: QuizCreate,
    //     layout: '/admin',
    // },
    // {
    //     path: '/quizResults',
    //     name: 'Итоги викторин',
    //     icon: 'fas fa-trophy',
    //     component: QuizResultList,
    //     layout: '/admin',
    // },
    // {
    //     path: '/stations',
    //     name: 'Станции',
    //     icon: 'fas fa-globe',
    //     component: StationList,
    //     layout: '/admin',
    // },
    // {
    //     isHiddenInMenu: true,
    //     path: '/stations/:id/edit',
    //     component: StationEdit,
    //     layout: '/admin',
    // },
    // {
    //     path: '/tgChannels',
    //     name: 'Телеграм каналы',
    //     icon: 'fab fa-telegram-plane',
    //     component: TgChannelList,
    //     layout: '/admin',
    // },
    // {
    //     isHiddenInMenu: true,
    //     path: '/tgChannels/:id/edit',
    //     component: TgChannelEdit,
    //     layout: '/admin',
    // },
    // {
    //     isHiddenInMenu: true,
    //     path: '/tgChannels/create',
    //     component: TgChannelCreate,
    //     layout: '/admin',
    // },
    // {
    //     path: '/journals',
    //     name: 'Журналы',
    //     icon: 'far fa-newspaper',
    //     component: JournalList,
    //     layout: '/admin',
    // },
    // {
    //     isHiddenInMenu: true,
    //     path: '/journals/:id/edit',
    //     component: JournalEdit,
    //     layout: '/admin',
    // },
    // {
    //     isHiddenInMenu: true,
    //     path: '/journals/create',
    //     component: JournalCreate,
    //     layout: '/admin',
    // },
    // {
    //     path: '/fcms',
    //     name: 'Push уведомления',
    //     icon: 'fas fa-mobile-alt',
    //     component: FcmList,
    //     layout: '/admin',
    // },
    // {
    //     isHiddenInMenu: true,
    //     path: '/fcms/:id/edit',
    //     component: FcmEdit,
    //     layout: '/admin',
    // },
    // {
    //     isHiddenInMenu: true,
    //     path: '/fcms/create',
    //     component: FcmCreate,
    //     layout: '/admin',
    // },
    // {
    //     path: '/reports',
    //     name: 'Отчеты',
    //     icon: 'fas fa-chart-bar',
    //     component: ReportList,
    //     layout: '/admin',
    // },
    // {
    //     isHiddenInMenu: true,
    //     path: '/reports/:id/edit',
    //     component: ReportView,
    //     layout: '/admin',
    // },
    // {
    //     path: '/logger',
    //     name: 'Протокол',
    //     icon: 'far fa-eye',
    //     component: LoggerList,
    //     layout: '/admin',
    // },
    // {
    //     isHiddenInMenu: true,
    //     path: '/logger/:id/edit',
    //     component: LoggerEdit,
    //     layout: '/admin',
    // },
    // {
    //     path: '/services',
    //     name: 'Сервисы',
    //     icon: 'fas fa-cog',
    //     component: ServiceList,
    //     layout: '/admin',
    // },
    // {
    //     path: '/settings',
    //     name: 'Настройки',
    //     icon: 'fas fa-tools',
    //     component: Settings,
    //     layout: '/admin',
    // },
];

export default routes;
