/*
Список сущностей, для которых предполагаются views со списком, редактированием, изменением и пр.
 */
const items = {
    articles: 'Статьи',
    tags: 'Тэги',
    categories: 'Категории',
    users: 'Авторы',
    mobilers: 'Пользователи',
    roles: 'Роли',
    support: 'Тех. поддержка',
    comments: 'Обр. связь',
    advices: 'Предложения',
    quizes: 'Викторины',
    channels: 'Каналы',
    tgChannels: 'Телеграм каналы',
    stations: 'Станции',
    quizResults: 'Итоги викторин',
    reports: 'Отчеты',
    // services: 'Сервисы',
    journals: 'Журналы',
    fcms: 'Push уведомления',
    // logger: 'Протокол',
    // security: 'Безопасность',
    settings: 'Настройки',
};

export type TItemName = keyof typeof items;

export default items;
