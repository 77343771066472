import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormikErrors } from 'formik/dist/types';
import { fileApi } from 'api';
import { isEmpty, isUndefined } from 'lodash';
import { Card, CardImg, CardText } from 'reactstrap';
import styled from 'styled-components';
import InvalidLabel from 'components/labels/InvalidLabel';

interface IReactDropzone {
    label?: string;
    invalidText?: string;
    preloadedImageUrl?: string;
    buttonText: string;
    name: string;
    nameFileId?: string;
    setFieldValue: (
        field: string,
        value: number | string | undefined,
        shouldValidate?: boolean | undefined
    ) => Promise<FormikErrors<object>> | Promise<void>;
    disabled?: boolean;
    clearValueOnError?: boolean;
}

const UploadContainer = styled.div<{ isLoaded: boolean; isInvalid: boolean }>`
    height: 30em;
    cursor: pointer;
    border: ${(props) => {
        if (props.isLoaded) {
            return 'none';
        }

        return props.isInvalid ? '1px solid #fb6340' : '1px dashed #e5e5e5';
    }};
    border-radius: 5px;

    margin-bottom: 0 !important;
`;

const StyledCard = styled(Card)`
    position: relative;
    overflow: hidden;

    &::after {
        display: block;
        padding-bottom: 100%;
    }
`;

const StyledCardImg = styled(CardImg)`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

const StyledCardText = styled(CardText)`
    font-weight: 400;
    color: #adb5bd;
`;

const ReactDropzone: React.FC<IReactDropzone> = ({
    label,
    buttonText,
    invalidText,
    preloadedImageUrl,
    name,
    nameFileId, //имя свойства с id созданного файла
    setFieldValue,
    disabled = false,
    clearValueOnError = true,
}) => {
    const [imageUrl, setImage] = useState<string>();

    useEffect(() => {
        if (!isUndefined(preloadedImageUrl)) {
            setImage(preloadedImageUrl);
        }
    }, [preloadedImageUrl]);

    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        multiple: false,
        accept: 'image/*',
        onDrop: async (file) => {
            const { data } = await fileApi.createImageFile(file[0]);
            if (!isUndefined(data.file)) {
                setImage(data.file.url);
                //если задано имя свойства с id созданного файла
                if (nameFileId) {
                    const results = [];
                    results.push(setFieldValue(name, data.file.url));
                    results.push(setFieldValue(nameFileId, data.file.id));
                    await Promise.all(results);
                }
                //если id созданного файла не используется
                else await setFieldValue(name, data.file.url);
            }
        },
        disabled,
    });

    const handleImgError = () => {
        setImage(undefined);
        if (clearValueOnError) setFieldValue(name, undefined);
    };

    return (
        <React.Fragment>
            {label && <label className="form-control-label">{label}</label>}
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <UploadContainer isInvalid={Boolean(invalidText)} isLoaded={!isEmpty(imageUrl)}>
                    <StyledCard className="text-center h-100 justify-content-center shadow-none mb-0">
                        {!isUndefined(imageUrl) && !isEmpty(imageUrl) ? (
                            <StyledCardImg
                                style={{ textAlign: 'center' }}
                                alt="Картинка не доступна"
                                src={imageUrl}
                                onError={() => handleImgError()}
                            />
                        ) : (
                            <StyledCardText>{buttonText}</StyledCardText>
                        )}
                    </StyledCard>
                </UploadContainer>
                {invalidText && <InvalidLabel>{invalidText}</InvalidLabel>}
            </div>
        </React.Fragment>
    );
};

export default ReactDropzone;
