import React from 'react';

import { observer } from 'mobx-react-lite';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useNavigate } from 'react-router-dom';

import ItemsListTable from 'components/tables/ItemsListTable';
import TableTextTooltipField from 'components/fields/TableTextTooltipField';
import ItemActionButtons from 'components/buttons/ItemActionButtons';
import { config } from 'lib/config';
import formatDate from 'lib/formatDate';
import { useStore } from 'store';
import Header, { TAction } from 'components/headers/ItemListHeaderEx';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';
import { useRqChannelListInvalidate } from '../queries';

const ArticleList: React.FC = observer(() => {
    const storeName = 'article';
    const navigate = useNavigate();
    //однократно обновить кеш списка телеграм каналов
    useRqChannelListInvalidate();

    const { currentUser } = useStore('admin');
    const { permissions } = currentUser;
    const userAccess = permissions && permissions.articles;

    const isActionButtonsHidden = userAccess?.update === false && userAccess?.delete === false;

    const columns: ColumnDescription[] = [
        {
            hidden: isActionButtonsHidden,
            dataField: 'edit',
            text: 'Действия',
            sort: false,
            formatter: (text, row) => (
                <ItemActionButtons
                    itemName="Статья"
                    storeName={storeName}
                    key={row.id}
                    id={row.id}
                    isEdit={userAccess?.update === true}
                    isDelete={userAccess?.delete === true}
                />
            ),
            headerStyle: {
                width: '5em',
            },
        },
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            headerStyle: {
                width: '7em',
            },
        },
        {
            dataField: 'title',
            text: 'Название',
            sort: true,
            formatter: (text, row) => <TableTextTooltipField id={row.id} text={text} isShowTooltip={true} />,
            style: {
                textOverflow: 'ellipsis',
                minWidth: '25em',
                maxWidth: '25em',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
        {
            dataField: 'isPublished',
            text: 'Опубликовано',
            sort: true,
            headerStyle: {
                width: '4em',
            },
            formatter: (isPublished) => (isPublished ? 'Да' : 'Нет'),
        },
        {
            formatter: (date, row) => <span key={row.id}>{formatDate(date, config.formats.time)}</span>,
            dataField: 'publishedAt',
            text: ' Дата публикации',
            sort: true,
            headerStyle: {
                width: '12em',
            },
        },
        // {
        //     dataField: 'isPublishedPortal',
        //     text: 'Портал',
        //     sort: true,
        //     headerStyle: {
        //         width: '4em',
        //     },
        //     formatter: (isPublishedPortal) => (isPublishedPortal ? 'Да' : 'Нет'),
        // },
        // {
        //     dataField: 'isPublishedTelegram',
        //     text: 'Телеграм',
        //     sort: true,
        //     headerStyle: {
        //         width: '4em',
        //     },
        //     formatter: (isPublishedTelegram) => (isPublishedTelegram ? 'Да' : 'Нет'),
        // },
        {
            formatter: (date, row) => <span key={row.id}>{formatDate(date, config.formats.time)}</span>,
            dataField: 'importantTo',
            text: 'Главная до',
            sort: true,
            headerStyle: {
                width: '12em',
            },
        },
        {
            formatter: (date, row) => <span key={row.id}>{formatDate(date, config.formats.time)}</span>,
            dataField: 'createdAt',
            text: 'Дата создания',
            sort: true,
            headerStyle: {
                width: '12em',
            },
        },
    ];

    //*************************************************************************************************************
    //Добавить действия
    const buttons: TAction[] = [];
    if (userAccess?.create === true) {
        buttons.push({
            name: 'Добавить статью',
            handle: () => navigate(`/admin/articles/create`),
        });
    }

    useResetListIfGoOut(storeName, 'articles');

    return (
        <ItemsListTable
            filters={[
                {
                    type: 'text',
                    name: 'textFilter',
                    filterTitle: 'Поиск по тексту',
                    dropdownItemTitle: 'Поиск по тексту',
                },
            ]}
            defaultTableParams={{
                page: 1,
                sizePerPage: 25,
                sortField: 'createdAt',
                sortOrder: 'desc',
            }}
            storeName={storeName}
            columns={columns}
        >
            <Header title="Список статей" buttons={buttons} />
        </ItemsListTable>
    );
});

export default ArticleList;
