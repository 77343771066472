import React from 'react';
import { isUndefined } from 'lodash';
import styled from 'styled-components';
import { UseFormRegister, FieldValues, Path } from 'react-hook-form';

const SelectContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

interface Props<T extends FieldValues> {
    register: UseFormRegister<T>;
    name: Path<T>;
    title?: string;
    disabled?: boolean;
}

const FormCheckBox = <T extends FieldValues>({ register, name, title, disabled }: Props<T>) => {
    return (
        <SelectContainer>
            <label className="custom-toggle">
                <input disabled={disabled} id={name} type="checkbox" {...register(name)} />
                <span className="custom-toggle-slider rounded-circle" />
            </label>
            {!isUndefined(title) && (
                <label className="form-control-label ml-2" htmlFor={name}>
                    {title}
                </label>
            )}
        </SelectContainer>
    );
};

export default FormCheckBox;
