import React from 'react';
import { FormGroup, Input } from 'reactstrap';

import CloseButton from 'components/buttons/CloseButton';

interface IDateSelect {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleClose: () => void;
    placeholder?: string;
    label?: string;
    value?: string;
}

const ItemText: React.FC<IDateSelect> = ({ handleClose, placeholder, label, onChange, value }) => {
    return (
        <div className="date-select-group">
            <FormGroup className="mb-1">
                {label && <label className="form-control-label">{label}</label>}
                <Input value={value} type="text" bsSize="sm" onChange={onChange} placeholder={placeholder} />
            </FormGroup>
            <CloseButton handleClose={handleClose} />
        </div>
    );
};

export default ItemText;
