import { makeAutoObservable } from 'mobx';

import { journalApi } from 'api';
import ListStore from 'store/common/List';
import ItemStore from 'store/common/Item';
import runItemsReactions from 'store/common/runItemsReactions';

export interface IJournalInList {
    id: number;
    title: string;
}

export interface IJournal {
    id: number;
    title: string;
    cover: string;
    coverId: number;
    content: string;
    contentId: number;
    publishedAt: Date;
    isPublished: boolean;
}

export interface IJournalUpdate {
    title?: string;
    cover?: string;
    coverId?: number;
    content?: string;
    contentId?: number;
    publishedAt?: Date;
    isPublished?: boolean;
}

export interface IJournalCreate {
    title: string;
    cover: string;
    coverId: number;
    content: string;
    contentId: number;
    publishedAt?: Date;
    isPublished?: boolean;
}

class JournalStore {
    constructor() {
        makeAutoObservable(this);
        runItemsReactions(this);
    }

    item = new ItemStore<IJournal, IJournalUpdate, IJournalCreate>(journalApi);

    readonly list = new ListStore<IJournalInList>(journalApi);
}

export default new JournalStore();
