import { makeAutoObservable } from 'mobx';

import { fileApi } from 'api';
import ListStore from 'store/common/List';
import ItemStore from 'store/common/Item';
import runItemsReactions from 'store/common/runItemsReactions';

export interface IFileInList {
    id: number;
    originalName: string;
    path: string;
    createdAt: Date;
    folder: {
        id: number;
        name: string;
    };
    createdBy: {
        id: number;
        username: string;
        firstname: string;
        lastname: string;
    };
}

export interface IFile {
    id: number;
    originalName: string;
}

export interface IFileUpdate {
    originalName: string;
}

export interface IFileCreate {
    originalName: string;
}

class StatStore {
    constructor() {
        makeAutoObservable(this);
        runItemsReactions(this);
    }

    item = new ItemStore<IFileInList, IFileUpdate, File>(fileApi);

    readonly list = new ListStore<IFileInList>(fileApi);
}

export default new StatStore();
