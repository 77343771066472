import React from 'react';
import { v4 as uuid } from 'uuid';
import { FormGroup, Input, Label } from 'reactstrap';

import CloseButton from 'components/buttons/CloseButton';

interface IData {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleClose: () => void;
    placeholder?: string;
    label?: string;
    value?: number;
}

const ItemCheckbox: React.FC<IData> = ({ handleClose, placeholder, label, onChange, value }) => {
    const v = uuid();

    return (
        <div className="date-select-group">
            <FormGroup check={true} className="mt-1 mb-1">
                <Input
                    type="checkbox"
                    checked={value !== 0}
                    onChange={onChange}
                    placeholder={placeholder}
                    id={`idCheck${v}`}
                />
                <Label for={`idCheck${v}`} check={true} style={{ fontSize: '0.9em' }}>
                    {label}
                </Label>
                <CloseButton handleClose={handleClose} />
            </FormGroup>
        </div>
    );
};

export default ItemCheckbox;
