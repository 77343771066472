import React from 'react';
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import { isEmpty, pickBy, isUndefined } from 'lodash';
import 'moment/locale/ru';

import { stat } from 'lib/stat';
import ItemText from 'components/filters/ItemText';
import ItemCheckbox from 'components/filters/ItemCheckbox';
import CloseButton from 'components/buttons/CloseButton';
import { stores } from 'store';
import { ListItemsStores } from 'lib/types';
import { observer } from 'mobx-react-lite';

//****************************************************************************************
//Список разрешенных типов фильров
const filterTypes = {
    //ввод текстовой строки
    text: {
        getField: ({
            filterName,
            filterTitle,
            listStore,
        }: {
            filterName: string;
            filterTitle: string;
            listStore: typeof stores[ListItemsStores]['list'];
        }) => (
            <ItemText
                value={listStore.queryParams.filters?.[filterName] as string}
                onChange={(e) => listStore.changeFilters({ [filterName]: e.target.value })}
                label={filterTitle}
                handleClose={() => listStore.disableFilter(filterName)}
            />
        ),
    },
    //чекбокс
    checkbox: {
        getField: ({
            filterName,
            filterTitle,
            listStore,
            eventDescriptor,
        }: {
            filterName: string;
            filterTitle: string;
            listStore: typeof stores[ListItemsStores]['list'];
            eventDescriptor?: string;
        }) => {
            return (
                //контрол работает с 0 и 1, а не с boolean
                //плюс неопределенное значение считаем за 0
                <ItemCheckbox
                    value={
                        !isUndefined(listStore.queryParams.filters?.[filterName]) &&
                        listStore.queryParams.filters?.[filterName] !== 0
                            ? 1
                            : 0
                    }
                    onChange={() => {
                        //событие
                        if (eventDescriptor) stat.addEvent({ name: eventDescriptor });
                        //применить фильтр
                        listStore.changeFilters({
                            [filterName]:
                                !isUndefined(listStore.queryParams.filters?.[filterName]) &&
                                listStore.queryParams.filters?.[filterName] !== 0
                                    ? 0
                                    : 1,
                        });
                    }}
                    label={filterTitle}
                    handleClose={() => listStore.disableFilter(filterName)}
                />
            );
        },
    },
};

//****************************************************************************************
//Отобразить элемент выпадающего списка фильров
const getDropdownItem = ({
    filterName,
    dropdownItemTitle,
    listStore,
}: {
    filterName: string;
    dropdownItemTitle: string;
    listStore: typeof stores[ListItemsStores]['list'];
}) => (
    <DropdownItem
        key={`filter-${dropdownItemTitle}`}
        disabled={listStore.filtersEnabled[filterName]}
        onClick={() => listStore.enableFilter(filterName)}
    >
        {dropdownItemTitle}
    </DropdownItem>
);

export interface IFiltersToolKit {
    listStore: typeof stores[ListItemsStores]['list'];
    filters: Array<{
        name: string;
        type: keyof typeof filterTypes;
        filterTitle: string;
        dropdownItemTitle: string;
        eventDescriptor?: string;
    }>;
}

const FiltersToolkit: React.FC<IFiltersToolKit> = observer(({ listStore, filters }) => {
    const isAllFiltersDisabled = isEmpty(pickBy(listStore.filtersEnabled, (value) => value));

    return (
        <Row>
            <Col xs={12} sm={6} className="align-self-center">
                {filters.map(
                    (filter) =>
                        listStore.filtersEnabled[filter.name] &&
                        filterTypes[filter.type].getField({
                            filterName: filter.name,
                            filterTitle: filter.filterTitle,
                            listStore,
                            eventDescriptor: filter.eventDescriptor,
                        })
                )}
            </Col>
            <Col xs={12} sm={6} className="align-self-center">
                <div id="datatable-basic_filter" className="d-flex dataTables_filter px-4 pb-1 float-right mb-4 mt-4">
                    <CloseButton
                        title="Очистить все фильтры"
                        isHidden={isAllFiltersDisabled}
                        handleClose={() => listStore.disableAllFilters()}
                    />
                    <UncontrolledDropdown group={true}>
                        <DropdownToggle size="sm" caret={true} color="secondary">
                            Добавить фильтр
                        </DropdownToggle>
                        <DropdownMenu>
                            {filters.map((filter) =>
                                getDropdownItem({
                                    filterName: filter.name,
                                    dropdownItemTitle: filter.dropdownItemTitle,
                                    listStore,
                                })
                            )}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </Col>
        </Row>
    );
});

export default FiltersToolkit;
