import React from 'react';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';

//import logo_digital_atom_media from 'assets/logo/logo_digital_atom_media.svg';
//import logo_digital_atom_media from 'assets/logo/pkii_share-logo.png';
import logo_digital_atom_media from 'assets/logo/1.svg';
import logo_digital_atom from 'assets/logo/logo_digital_atom.svg';
import logo_rosenergoatom from 'assets/logo/logo_rosenergoatom.svg';
import Notification from 'components/notifications/Notification';

const LoginBackground: React.FC = ({ children }) => {
    return (
        <React.Fragment>
            <Container className="mt-9">
                <Row className="justify-content-center">
                    <Col lg="8" md="7" className="px-0">
                        <Card className="bg-secondary border-0 mb-0">
                            <CardBody className="px-lg-5 py-lg-5">
                                <Row>
                                    <Col className="col-6 d-flex flex-column justify-content-between align-items-start pl-0 pr-2">
                                        {/* header */}
                                        <img
                                            className="mw-100"
                                            style={{ height: '30%' }}
                                            alt="Картинка не доступна"
                                            src={logo_digital_atom_media}
                                        />
                                        {/* <h1 className="text-dark display-3 my-0">Media</h1> */}
                                        <div className="text-muted mt-1 pr-5">
                                            <small className="text-dark">
                                                Платформа для управления корпоративным контентом из одной точки
                                            </small>
                                        </div>
                                        {/* выравнивание по высоте */}
                                        <div className="mt-7" />
                                        {/* footer */}
                                        <div style={{ height: '12%' }} className="d-flex align-self-stretch mt-auto">
                                            <Col className="col-5 d-flex flex-column justify-content-center align-items-start ml-0 pl-0">
                                                <img
                                                    alt="Картинка не доступна"
                                                    src={logo_digital_atom}
                                                    className="mw-100 mh-100"
                                                />
                                            </Col>
                                            <Col className="d-flex flex-column justify-content-center align-items-start ml-2 pl-0">
                                                <img
                                                    alt="Картинка не доступна"
                                                    src={logo_rosenergoatom}
                                                    className="mw-100 mh-100"
                                                />
                                            </Col>
                                        </div>
                                    </Col>

                                    {/* Вывод дочерних компонентов в правую колонку */}
                                    <Col className="col-6 pr-0">{children}</Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Notification />
        </React.Fragment>
    );
};

export default LoginBackground;
