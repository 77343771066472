import { useState, useRef } from 'react';

//список закладок
export const tabList = [
    { title: 'Моб приложение', href: '#mobile', tabId: 1 },
    // { title: 'Портал', href: '#portal', tabId: 2 },
    // { title: 'Телеграм', href: '#telegram', tabId: 3 },
];

//*************************************************************************************************************
//хук управляет состоянием текущей закладки
export const useTabs = (tabId: number) => {
    const [activeTab, setTab] = useState(tabId);

    const setTabId = (id: number) => {
        if (tabList.findIndex((tab) => tab.tabId === id) === -1) return;
        setTab(id);
    };

    return {
        activeTab,
        setTab: setTabId,
    };
};

//*************************************************************************************************************
//хук управляет отслеживанием несохраненных данныъ в закладках
export const useUnsavedTabs = (activeTab: number, setTab: (i: number) => void) => {
    //id несохраненной закладки
    const unsavedTab = useRef(0);
    //id закладки, на которую планируется переход
    const newTabId = useRef(0);
    //показать/скрыть запрос на смену закладки
    const [changeTabRequest, setChangeTabRequest] = useState(false);

    //установить id несохраненной закладки
    const setUnsavedTab = (tabId: number, isSaved: boolean) => {
        if (!isSaved && !unsavedTab.current) unsavedTab.current = tabId;
        else if (isSaved && unsavedTab.current === tabId) unsavedTab.current = 0;
    };

    //проверить на сохранение данных и перейти на новую закладку
    //если tabId не задан, то предполагается переход на закладку newTabId
    //check - признак выполнения проверки (запроса к пользователю)
    const setTabWithCheck = (check: boolean, tabId?: number) => {
        const id = tabId || newTabId.current;
        if (activeTab === id) return;
        //если есть несохраненные данные && check, то запрос
        if (unsavedTab.current && check) {
            newTabId.current = id;
            setChangeTabRequest(true);
        }
        //иначе - смена закладки
        else {
            unsavedTab.current = 0;
            newTabId.current = 0;
            setTab(id);
        }
    };

    return {
        setTabWithCheck,
        setUnsavedTab,
        changeTabRequest,
        setChangeTabRequest,
    };
};
